<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";

  import { getBankAccounts, createBankAccount, deleteBankAccount } from '@/api/BankAccounts.js'
  import { BankAccount } from "@/models/BankAccount";

  /**
   * Basic-table component
   */
  export default {
    page: {
      title: "Contas Bancárias",
      meta: [{ name: "description", content: appConfig.description }],
    },
    components: { Layout, PageHeader },
    data() {
      return {
        title: "Contas Bancárias",
        items: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Contas Bancárias",
            active: true,
          },
        ],
        list:[],
        forms: {
          bankAccount: {
            bank_id: null,
            bank_name: null,
            agency: null,
            number: null,
            digit: null
          }
        },
        ui:{
          forms: {
            bankAccount: {
              disabled: false
            }
          },
        }
      };
    },
    computed: {
      hasItems: function () {
        return this.list.length > 0
      }
    },
    created () {
      this.fetchData();
    },
    methods: {
      fetchData() {
        let vm = this
        getBankAccounts().then(response => {
          vm.list = response.map(item => {
            return new BankAccount(item)
          })
        })
      },
      getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null;
      },
      handleOk(bvModalEvt){
        bvModalEvt.preventDefault()

        this.$refs.observer.validate().then(result => {
          if(result){
            this.createBankAccount()
            this.$bvModal.hide('modal-bank-account')
          }
        });
      },
      createBankAccount: function () {
        let vm = this

        if(this.ui.forms.bankAccount.disabled == false){
          this.ui.forms.bankAccount.disabled = true
          let obj = {
            bank_id: this.forms.bankAccount.bank_id,
            bank_name: this.forms.bankAccount.bank_name,
            agency: this.forms.bankAccount.agency,
            number: this.forms.bankAccount.number,
            digit: this.forms.bankAccount.digit
          };

          vm.$awn.async(
            createBankAccount(obj),
            function (result) {
              vm.$awn.success("Dados criados com sucesso.");
              vm.list.unshift(new BankAccount(result));
              vm.ui.forms.bankAccount.disabled = false;
            },
            function (error) {
              vm.$awn.warning(error || 'Ocorreu um erro ao efetuar a operação. Favor tentar novamente.');
              vm.ui.forms.bankAccount.disabled = false;
            }
          );
        }
      },
      deleteBankAccount(id, index){
        let vm = this
        this.$awn.confirm("Tem certeza de que deseja executar esta operação?", function(){
          vm.$awn.async(
            deleteBankAccount(id),
            function () {
              vm.list.splice(index, 1)
              vm.$awn.success("Conta bancária removida com sucesso.")
            }, function () {
              vm.$awn.warning('Ocorreu um erro ao efetuar a operação. Favor tentar novamente.')
            }
          );
        });
      }
    }
  };
</script>

<template>
  <Layout>
    <PageHeader
      :title="title"
      :items="items"
    />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-striped mb-0">
                <thead>
                <tr>
                  <th class="min">#</th>
                  <th>Banco</th>
                  <th>Agência</th>
                  <th>Conta Corrente</th>
                  <th>Data</th>
                  <td class="min"></td>
                </tr>
                </thead>
                <tbody>
                <tr v-if="!hasItems">
                  <td colspan="100%" class="text-center">
                    <p class="py-4 mb-0">Nenhum resultado encontrado.</p>
                  </td>
                </tr>
                <tr v-else v-for="(item, index) in list" v-bind:key="item.id">
                  <th scope="row">
                    {{ item.id }}
                  </th>
                  <td>
                    {{ item.bank_id }} - {{ item.bank_name }}
                  </td>
                  <td>
                    {{ item.agency }}
                  </td>
                  <td>
                    {{ item.account }}
                  </td>
                  <td class="min">{{ $d(item.created_at, 'long') }}</td>
                  <td class="min">
                    <button
                      type="button"
                      class="btn btn-light btn-sm btn-rounded"
                      @click="deleteBankAccount(index, id)"
                    >
                      <i class="bx bx-trash" />
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  
    <b-button
      variant="primary"
      size="lg"
      class="btn-rounded-fixed"
      pill
      @click="$bvModal.show('modal-bank-account')">
      <i class="bx bx-plus"></i>
    </b-button>
  
    <b-modal
      id="modal-bank-account"
      centered
      title="Adicionar Conta Bancária"
      title-class="font-18"
      :cancel-title="'Cancelar'"
      :ok-title="'Salvar'"
      @ok="handleOk"
    >
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form
          @submit.stop.prevent="handleSubmit(createBankAccount)"
          class="form-horizontal"
        >
          <validation-provider
            name="Código do Banco"
            :rules="{ required: true, min:3, numeric: true }"
            v-slot="bank_id"
          >
            <b-form-group
              label-cols-sm="4"
              label-cols-lg="4"
              label="Código do Banco"
              label-for="text"
            >
            
              <b-input
                v-model="forms.bankAccount.bank_id"
                v-mask="'####'"
                :state="getValidationState(bank_id)"/>
              <b-form-invalid-feedback>{{ bank_id.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
  
          <validation-provider
            name="Nome do Banco"
            :rules="{ required: true }"
            v-slot="bank_name"
          >
            <b-form-group
              label-cols-sm="4"
              label-cols-lg="4"
              label="Nome do Banco"
              label-for="text"
            >
  
              <b-input
                v-model="forms.bankAccount.bank_name"
                :state="getValidationState(bank_name)"/>
              <b-form-invalid-feedback>{{ bank_name.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
  
          <validation-provider
            name="Agência"
            :rules="{ required: true, min:4, numeric: true }"
            v-slot="agency"
          >
            <b-form-group
              label-cols-sm="4"
              label-cols-lg="4"
              label="Agência"
              label-for="text"
            >
  
              <b-input
                v-model="forms.bankAccount.agency"
                v-mask="'########'"
                :state="getValidationState(agency)"/>
              <b-form-invalid-feedback>{{ agency.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
  
          <validation-provider
            name="Número da Conta"
            :rules="{ required: true, numeric: true, min: 5 }"
            v-slot="number"
          >
            <b-form-group
              label-cols-sm="4"
              label-cols-lg="4"
              label="Conta Corrente"
              label-for="text"
            >
  
              <b-input
                v-model="forms.bankAccount.number"
                v-mask="'########'"
                :state="getValidationState(number)"/>
              <b-form-invalid-feedback>{{ number.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
  
          <validation-provider
            name="Dígito"
            :rules="{ numeric: true, max: 1 }"
            v-slot="digit"
          >
            <b-form-group
              label-cols-sm="4"
              label-cols-lg="4"
              label="Dígito"
              label-for="text"
            >
  
              <b-input
                v-model="forms.bankAccount.digit"
                v-mask="'#'"
                :state="getValidationState(digit)"/>
              <b-form-invalid-feedback>{{ digit.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          
          <template v-slot:modal-footer>
            <b-button size="sm" variant="success" type="submit">
              Salvar
            </b-button>
            <b-button size="sm" variant="danger" @click="$bvModal.hide('modal-user')">
              Cancelar
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </Layout>
</template>
